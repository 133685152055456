// First Rate Movers - Estimate Form Helper Script (version loaded depends on OME_VERSION in the PHP form)
// 	Module Pattern via IIFE / anonymous closure (vs alternative singleton patterns)
// 	Globals brought into scope: Jquery, window, document
// 	For sandboxing & minification (and undefined unmutable), also prevents strict/nonstrict concat issues
// 	NOTE: I prefer this syntax without the ! operator, but if concatenating multiple js files may need to change this
// 	NOTE: Use loose augmentaton for peformance if splitting to multiple files

// DEBUGGING NOTE: If enabling stylepicker or data debugger, remove the IIFE closure

// NOTE: Obfuscation is done with https://obfuscator.io/ using specific settings

var FRM = FRM || {};
FRM.EstimateRequest = (function ($, window, document, undefined) {

	// STRICT - Since JS 1.8.5 (ECMAScript v5), supported in IE10/Chrome13/FF4/Safari5.1
	"use strict";

	// CRITICAL NOTES:
	//		- momentJS moment's are mutable, so clone when using methods that adjust the moment's value (like .add(X,"days"), etc)
	//		- momentJS is configured to operate in EST, but Pikaday uses JS Dates for many operations and drops time/hours/zone causing issues that require adjustments (mindate, onchange, etc)
	//		- daysOffset is computed for PikadayResponsive to timezone-correct in "change" event for move date, another offset was created to correct mindate for timezones earlier than EST
	//		- there are modes for calendar debugging and styling, with helper UI for testing both

	// CONFIG - PARAMS
	var g_DEBUGGING = (getUrlParameter("d")) ? (getUrlParameter("d") == 1) : false;				// default true/false
	var g_DEBUGGING_DATES = (getUrlParameter("dd")) ? (getUrlParameter("dd") == 1) : false;				// default true/false
	var g_DO_NOT_DISABLE_DAYS = (getUrlParameter("dnd")) ? (getUrlParameter("dnd") == 1) : false;				// default true/false
	var g_APP_TZ_OVERRIDE = (getUrlParameter("tz")) ? getUrlParameter("tz") : null;				// override application (FRM EST) timezone for calendar testing
	var g_CALSTYLE = (getUrlParameter("c")) ? parseInt(getUrlParameter("c")) : 1;					// deafult to 1
	var g_SUPPRESS60DAYWARNING = (getUrlParameter("sw")) ? (getUrlParameter("sw") == 1) : (g_SHOWSTYLEPICKER || (getUrlParameter("c") !== "") ? true : false);
	var g_SHOWSTYLEPICKER = (getUrlParameter("sp")) ? (getUrlParameter("sp") == 1) : false;				//TODO-STYLES: Remove this when removing stylepicker / g_calstyle
	var g_MATTFACEBG = (getUrlParameter("mg")) ? (getUrlParameter("mg") == 1) : false;				//TODO-STYLES: Remove this when removing stylepicker / g_calstyle
	var g_SAMMYFACEBG = (getUrlParameter("sg")) ? (getUrlParameter("sg") == 1) : false;				//TODO-STYLES: Remove this when removing stylepicker / g_calstyle

	// CONFIG - CALENDAR
	var CAL_YEARS_TO_ALLOW = 3;								// How far into the future one can request
	var CAL_CALL_WITHIN_X_DAYS = 60;								// Tell customer to call if moving within this many days from today
	var MONTHS_AT_ONCE = 1;								// How many calendar months visible at once (overridden based on user's features)

	// GLOBAL CONSTANTS - DIALOGS
	var $FRM_DIALOG = null;							// JQ UI Dialog
	// GLOBAL CONSTANTS - VALIDATION
	var VALIDATION_ERROR_CLASS = "validation-error";		// Class to highlight invalid fields (fields that have failed validation)
	var VALIDATION_ERROR_CLASS_MOVEDATE = "validation-movedate-invalid"	// Specifically for MoveDate input (Pikaday-generated field)
	// GLOBAL "CONSTANTS" - DATE AND TIMEZONE
	var MOMENT_STRICT = true;							// Always use strict when code paths allow for it
	var DEFAULT_TIMEZONE = "America/Toronto";			// Best for Ottawa = Toronto = New York
	var MOMENT_TZ_EST = null;							// Application default timezone to override output format of the moment object (.format, .hours, etc)  * NOTE: set null to default to browser TZ
	var BROWSER_TZ = null;							// User/Browser timezone (best guess) used to adjust move date (Pikaday) dates to application's EST timezone
	var MOMENT_UTC_OFFSET = null;							// Application default timezone (Toronto/New York) 	* undefined if no default timezone is set (problem)
	var BROWSER_UTC_OFFSET = null;							// User/Browser timezone offset from UTC, used to adjust Pikaday (TODO: will the fallback work in IE 6-10 as per Intl API?)
	var MOM_TODAY_EST = null;							// This is set by calendar prep code (for ensuring operations and computations in EST time zone properly)
	var CAL_DAYS_TO_OFFSET = null;							// This is set by calendar prep code (for computing timezone offset to initialize calendar, option becomes hidden in pikaday)
	var DATE_FORMAT_YYYYMMDD = "YYYY-MM-DD";				// Default for comparing, parsing and debuging
	var DATE_FORMAT_DEBUG = "YYYY-MM-DD HH:mm z";		// Debugging: Show short time and zone where required
	var DATE_FORMAT_RFC3339 = moment.defaultFormatUtc;	// For Google Calendar API (startTime requires RFC3339/ISO8601 = moment's defaultFormatUtc "YYYY-MM-DDTHH:mm:ssZ") (non-Utc +XX:00 breaks API)
	// GLOBAL CONSTANTS - CALENDAR & CONFIG
	var ORIG_BEDROOM_FIELD_NAME = "OrigBdrms";					// Used to check for Big Moves on Small Move dates
	var MOVE_DATE_SESSION_STORAGE_KEY = "FRM_MOVE_DATE";
	var MOVE_DATE_FIELD_NAME = "MoveDate";
	var MOVE_DATE_INPUT_FIELD_NAME = MOVE_DATE_FIELD_NAME + "-input";	// e.g. Pikaday will create the input field as "MoveDate-input"
	var DATE_FORMAT_DISPLAY = "MMMM Do, YYYY";			// Add HH:mm to format for debugging selected date
	var DATE_FORMAT_OUTPUT = "YYYY-MM-DD";				// Add HH:mm to YYYY-MM-DD for certain debugging purposes but it will break setDate as that uses this format too internally
	var DATE_FORMAT_PIK_UI = "YYYY-M-D";					// Format when peicing together data attributes from pika-days (month and day are not padded out with 0's)
	var INVALID_DATE = "Invalid date";				// The string that pikaday and momentjs uses to represent an invalid date
	var WEEKDAYS = { SUNDAY: 0, MONDAY: 1, TUESDAY: 2, WEDNESDAY: 3, THURSDAY: 4, FRIDAY: 5, SATURDAY: 6 };		// WEEDKDAYS (Sunday = 0)
	var ONTARIO_HOLIDAYS = [];
	var GCAL_OVERRIDES = [];
	var ARRAY_IS_SORTED = true;							// This is required for Underscore's _.indexOf() param isSorted to force faster binary search (requires arrays to be sorted)
	// GOOGLE API FOR OVERRIDES (including AVAILABILITY="CLOSED"/"BOOKED"/"OPEN" & RATES ("BASERATE","MIDRATE","PEAKRATE")
	var GCAL_APIKEY = "AIzaSyCHlwGG5BhZTlpdX9k9ZmonKQWwJttDjsA";
	var GCAL_CALENDAR_ID = 'l9kq5trl8bh86l3ka5vench54o%40group.calendar.google.com'; // %40 encoding for the @ is recommended
	var GCAL_EVENTS_URL = "https://content.googleapis.com/calendar/v3/calendars/" + GCAL_CALENDAR_ID + "/events";
	var GCAL_MAX_RESULTS = 2500; // over 3 years of 2 event each day (no need to surpass the single-page max of 2500 results)

	// GLOBAL PIKADAY VARS
	var g_MoveDatePicker = null;
	var $g_movedate = $("#" + MOVE_DATE_FIELD_NAME);
	var $g_movedateInput = null;	// Will be created by Pikaday

	// GLOBAL STATE VARS
	var g_DATEWASRESTORED = false;	// Set to true if restoring date from hiddens/storage (especially and really only on back button)

	// Preloading images
	function preloadImage(url) { var img = new Image(); img.src = url; }

	// Initialze (though it does not require waiting for the document to load, and could be executed when this script loads/runs)
	$(document).ready(function () {
		init_EstimateForm();
	});

	// Prepare the form ONLY after the DOM is ready (simulating the old body onload method)
	$(window).load(function () {
		prepareForm();
	});

	// INITIALIZE ESTIMATE FORM
	function init_EstimateForm() {

		// Preload JS Dialog icons (actually done on HTML/PHP side as it is rendering the style that matters for JQ icons)
		// preloadImage("https://ajax.googleapis.com/ajax/libs/jqueryui/1.11.4/themes/redmond/images/ui-icons_d8e7f3_256x240.png");

		// OPERATIONAL TIMEZONE - Set timezone to America/New_York (aka US/Eastern)
		// NOTE: Really only need all timezone data if using browserTimeZone / tz.guess below but is this required?
		//			- Could use moment-timezone without data, then tz.add(..copy new york data from online source data..)+.link("Ottawa")
		// 		- But don't - it would be smaller but then no support included for other timezones, maintenance issue/risk
		// REF: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
		// 		"Atlantic/Azores"		+1
		//			"America/Toronto"		-5		LINK: "America/New_York"
		//			"Asia/Taipei"			+8
		// Set application timezone
		MOMENT_TZ_EST = DEFAULT_TIMEZONE; 		// Default to EST (America/Toronto)
		if (g_APP_TZ_OVERRIDE) MOMENT_TZ_EST =
			(g_APP_TZ_OVERRIDE == "earliest") ? "Etc/GMT+12" :
				(g_APP_TZ_OVERRIDE == "sask") ? "Canada/Saskatchewan" :
					(g_APP_TZ_OVERRIDE == "taipei") ? "Asia/Taipei" :
						(g_APP_TZ_OVERRIDE == "latest") ? "Pacific/Kiritimati" :
							(g_APP_TZ_OVERRIDE == "default") ? DEFAULT_TIMEZONE :
								MOMENT_TZ_EST;

		if (MOMENT_TZ_EST) { moment.tz.setDefault(MOMENT_TZ_EST); }
		MOM_TODAY_EST = getTodaysDateEST();
		MOMENT_UTC_OFFSET = moment().utcOffset();
		BROWSER_TZ = moment.tz.guess(true);
		BROWSER_UTC_OFFSET = moment.tz(BROWSER_TZ).utcOffset();

		CAL_DAYS_TO_OFFSET = getCalendarDaysToOffsetToDefaultTimezone();

		// LOAD THE CALENDAR (not waiting for full doc ready, start getting the data while images are still loading)
		try {
			// NOTE: This is detecting the zoomed width (so iphone 368px SCREEN.WIDTH = 712px INNERWIDTh @ VIEWPORT/SCALE = 620/0.5 ==> 2 months which is good since REAL ESTATE IS ACTUALLY 620px)
			// TODO: V2 will use different mobile detection and display, so will need to accomodate the change
			MONTHS_AT_ONCE = ($(window).innerWidth() < 620) ? 1 : ($(window).innerWidth() < 1000) ? 2 : 3;
			// PREPARE HOLIDAYS (will be in calendar too)
			ONTARIO_HOLIDAYS = [
				// HOLIDAYS (Ontario) - Used all but Mother's day & Father's Day  (https://www.officeholidays.com/countries/canada/ontario/2018.php)
				"2018-01-01", "2018-02-19", "2018-03-30", "2018-05-21", "2018-07-02", "2018-08-06", "2018-09-03", "2018-10-08", "2018-12-25", "2018-12-26",
				"2019-01-01", "2019-02-18", "2019-04-19", "2019-05-20", "2019-07-01", "2019-08-05", "2019-09-02", "2019-10-14", "2019-12-25", "2019-12-26",
				"2020-01-01", "2020-02-17", "2020-04-10", "2020-05-18", "2020-07-01", "2020-08-03", "2020-09-07", "2020-10-12", "2020-12-25", "2020-12-26",
				"2021-01-01", "2021-02-15", "2021-04-02", "2021-05-24", "2021-07-01", "2021-08-02", "2021-09-06", "2021-10-11", "2021-12-25", "2021-12-26",
				// Confirm 2021 holidays, add 2022 holidays
			];
			// PREPARE GOOGLE CALENDAR OVERRIDES
			var GCAL_START_TIME = MOM_TODAY_EST.format(DATE_FORMAT_RFC3339); // e.g. "2019-10-11T13%3A28%3A43.317Z";
			var GCAL_EVENTS_QSFILTERS = "maxResults=" + GCAL_MAX_RESULTS + "&orderBy=startTime&showDeleted=false&singleEvents=true&timeMin=" + GCAL_START_TIME; // Use orderBy startTime so _.indexOf is binary search (isSorted=true)
			var GCAL_OVERRIDES_URL = GCAL_EVENTS_URL + "?" + GCAL_EVENTS_QSFILTERS + "&key=" + GCAL_APIKEY;
			$.getJSON(GCAL_OVERRIDES_URL, { get_param: 'value' })
				.done(function (data) {
					$.each(data.items, function (index, item) {
						GCAL_OVERRIDES.push({ "date": item.start.date, "summary": item.summary.toLowerCase() });
					});
					//debug_to_console_js("OVERRIDES: " + GCAL_OVERRIDES.map(e => e.date+","+e.summary).join("|") );
				})
				.fail(function (jqxhr, textStatus, error) {
					msg("Unable to load the calendar (A0003).<br />Please clear the cache and reload the page.", "Error", true);
					debug_to_console_js("BOOKING ERROR: Failed to load booking availability."); // ... + error + " [status=" + textStatus + "]"); (too much detail, unless reporting to TrackJS/Analytics)
				})
				.always(function () {
					// RENDER CALENDAR (pikaday calendar) whether or not there were issues retrieving avaiability
					renderCalendar();
				});

		} catch (ex) {
			msg("Unable to load the calendar (A0001).<br />Please clear the cache and reload the page.", "Error", true);
			error_to_console_js("FETCH ERROR: " + ex.message + " >>> " + ex.stack);
		}
	}


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// AGING VALIDATION CODE (should be replaced a long time ago, but V2 will be soon)
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// OBSOLETE: synchTo - Function to synch the email field to the human verification field (no longer used)
	// function synchTo(thisform,src,dest) { eval("thisform." + dest).value = src.value; } //thisform.getElementById(dest).value = src.value;

	// OLD: Function to prepare the Year Drop-down to contain only current year + 2
	// (but only do it when the page first loads, but on on BACK to fix data previously input)
	// RG-28APR2015: Updated this routine to replace the 3 non-js default year options (those were added to enable non-js users to submit)
	function prepareForm() {
		/* RG-25SEP2019: NEW PIKADAY: This logic is no longer used, and instead restoration of submitted date on user pressing BACK is at the end of renderCalendar() re-firing
		ddl = thisform.YY;
		if ((ddl.options.length == 0) || (ddl.options.length == 3)) {
			todayDate = new Date();
			curr_year = todayDate.getFullYear();
			for (var i = 0; i < 3; i++) {
					ddl.options[i] = new Option(curr_year + i, curr_year + i);
			}
			// If the user pressed back, and had previously selected a date use it
			if (thisform.preservedYearIndex.value.length > 0) {
					thisform.YY.selectedIndex = thisform.preservedYearIndex.value;
			}
		} */

		// RG-17NOV2019: New Javascript-Enabled logic
		// IMPORTANT: Do not dynamical prepend the field to the form this way (causes the form to lose it's data on BACK button in some browsers like Chrome 78) so now defaulting to 0, updating here to 1
		//$("<input type=\"text\" name=\"JAVASCRIPT_ENABLED\" id=\"JAVASCRIPT_ENABLED\" value=\"1\" />").prependTo("form#EstimateForm");
		try { $("#JAVASCRIPT_ENABLED").val("1"); } finally { }

		// RG-17NOV2019: Remove default-HTML5 required field flags that are only intended for users that have javascript disabled
		$(":input[required]").removeAttr("required");

		// RG-28NOV2019: Added tracking for the following stats (populating hidden fields to pass along)
		try { $("#BROWSER_TIMEZONE").val(BROWSER_TZ); } finally { }				// NOTE: This is a best-guess timezone from momentJS
		try { $("#BROWSER_OFFSET").val(BROWSER_UTC_OFFSET); } finally { }
		try { $("#SCREEN_WIDTH").val(screen.width); } finally { }					// NOTE: Actual screen dimensions
		try { $("#SCREEN_HEIGHT").val(screen.height); } finally { }
		try { $("#INNER_WIDTH").val(window.innerWidth); } finally { }			// NOTE: Usable screen dimensions
		try { $("#INNER_HEIGHT").val(window.innerHeight); } finally { }

		//HOOK: Call onload hook if defined
		if (typeof hook_onload_prepareform === "function") { hook_onload_prepareform(); }

		//TODO: Only enabled for testing for ease of focus and back button (and if testing for user click before page fully loads including external scripts, images etc)
		//Scroll to the start of the estimate form nicely (first load, or back / refresh)
		if (g_DEBUGGING || g_DEBUGGING_DATES) scrollToEstimateForm();
	}

	/* RG-25SEP2019: NEW PIKADAY: This logic is no longer used
	// Preserve the year in a hidden field in case the user presses the back button (preventing unintended errors in dates)
	function preserveSelection(thisform) {
		thisform.preservedYearIndex.value = thisform.YY.selectedIndex;
	}*/

	// Check for garbage dates
	// TODO: Should upgrade this to moment-based check but throw-away anyhow and it is a working moment-alternative redundancy
	function IsValidDate(yyyy, mm, dd) {
		// NOTE: JavaScript starts the month from 0
		var jsDate = new Date(mm + "/" + dd + "/" + yyyy);
		return ((jsDate.getDate() == dd) && (jsDate.getMonth() == (mm - 1)) && (jsDate.getFullYear() == yyyy));
	}
	// Submit the form (or attempt to)
	function submitForm(objForm) {
		// Immediately disable the submit button to prevent all the multiple submissions / double-clicks etc, then re-enable conditionally below
		disableSubmit();

		if (isFormValid(objForm)) {
			// VALID: Submit the form
			// Disable the submit button / form submission for 10 seconds (not permanently, in case of navigation back on server-side submission failure), and submit the form
			setTimeout(function () { enableSubmit(); }, 10000);	// Should never happen in modern/webkit browsers?

			// Attempt to preserve the move date for browsers that do not restore manipulated hidden fields like Chrome 78
			// NOTE: Move Date hidden inputs are already validated, so just convert them to YYYY-MM-DD
			var strMoveDate = createDateString(objForm.elements["YY"].value, objForm.elements["MM"].value, objForm.elements["DD"].value);
			debug_to_console_js("   - MOVE DATE: Persisting Move Date: " + strMoveDate);
			try { sessionStorage.setItem(MOVE_DATE_SESSION_STORAGE_KEY, strMoveDate); debug_to_console_js("   - MOVE DATE: Persisted to storage: " + strMoveDate); } finally { }

			return true;
		} else {
			// INVALID: Do not submit
			// Re-enable the submit button / form submission immediately
			enableSubmit();
			return false;
		}
	}
	function disableSubmit() {
		// Prevent the user from double-clicking the submit button, or submitting twice in rapid succession
		$("#submit").attr("disabled", true).addClass("submitDisabled");
	}
	function enableSubmit() {
		$("#submit").attr("disabled", false).removeClass("submitDisabled");
	}
	// Function to validate the form
	// NOTE: This really ought to be upgraded to better standards for form and element references (e.g. reference fields by ID always if possible, here the old script portions are name-based)
	var g_firstErrorFieldName = "";
	function isFormValid(thisform) {
		try {
			// By default, we will assume no validation errors (bad assumption, should reset all to default colour before validation to account for corrections)
			g_firstErrorFieldName = ""

			// Move Date input field
			var f_movedateInput = $g_movedateInput[0];
			var currFieldName, currField;
			var arrRequiredFields = $("#form-required-fields").val().split(",");		// thisform['required'].value.split(",");		//

			// Reset all validation errors (reset the style to default)
			for (var i = 0; i < arrRequiredFields.length; i++) {
				// Except for "MoveDate-input"
				currFieldName = arrRequiredFields[i];
				currField = thisform.elements[currFieldName];
				if (currFieldName === f_movedateInput.id) {
					unmarkField_MoveDate();
				} else {
					if (currField.type !== "hidden") {
						unmarkField(currField);
					}
				}
				// These are validated fields but not always required:
				unmarkField(thisform.elements["Furny"]);
				unmarkField(thisform.elements["SmBoxes"]);
			}

			// Validate the Move Date FIRST as it is most important
			// RG-25SEP2019: NEW PIKADAY: Now using hidden text fields instead of dropdowns
			let intMoveYear = thisform.elements["YY"].value;	// thisform.YY[thisform.YY.selectedIndex].value;
			let intMoveMonth = thisform.elements["MM"].value;	// thisform.MM[thisform.MM.selectedIndex].value;
			let intMoveDay = thisform.elements["DD"].value;	// thisform.DD[thisform.DD.selectedIndex].value;
			if (!IsValidDate(intMoveYear, intMoveMonth, intMoveDay)) {
				// RG-25SEP2019: NEW PIKADAY: Now highlight the movedate field, not the now-hidden component fields
				//markField(thisform.YY, COLOR_FIELD_ERROR); markField(thisform.MM, COLOR_FIELD_ERROR); markField(thisform.DD, COLOR_FIELD_ERROR);
				//thisform.MoveDate.focus();
				//window.scrollTo(0, 0);
				markField_MoveDate();
				f_movedateInput.focus();
				msg("Please select a valid move date.");
				return false;
			} else {
				// RG-25SEP2019: NEW PIKADAY: Now using moment to be timezone-safe but still using the hidden fields as they are still the real DATA
				// Validate the move date is today or later
				//let dateTodayFullDate = new Date();
				//let dateToday = new Date(dateTodayFullDate.getFullYear(), dateTodayFullDate.getMonth(), dateTodayFullDate.getDate());
				//let dateMoveDate = new Date(intMoveYear, intMoveMonth - 1, intMoveDay);  // javascript months have 0-based index
				//if (dateMoveDate < dateToday) {
				// Apply the computed dayOffset just like PR internals, as this moment is created with a JS Date object by Pikaday (indirectly via the hidden fields)

				// Convert the hidden date fields to an EST-based zero-hour moment for comparisons (this part works just fine)
				// NOTE: Only consumer initially for JS Date is disableDayFn and it passes in the zero-hour date of the calendar day which is actually browser timezone day so need to FULLY adjust if JS Date
				var mMoveDateSelected = moment(createDateString(intMoveYear, intMoveMonth, intMoveDay), DATE_FORMAT_YYYYMMDD); //.startOf("day");		// NOTE: NOT USING THE STRICT CONSTANT AS USER COULD FILL HIDDENS WITH "2" VS "02"
				//debug_to_console("isBeforeToday( " + mMoveDateSelected.format(DATE_FORMAT_DEBUG) + " ) = " + mMoveDateSelected.isBefore(MOM_TODAY_EST,"day"));
				if (mMoveDateSelected.isBefore(MOM_TODAY_EST, "day")) {
					// RG-25SEP2019: NEW PIKADAY: Now highlight the movedate field, not the now-hidden component fields
					//markField(thisform.YY, COLOR_FIELD_ERROR); markField(thisform.MM, COLOR_FIELD_ERROR); markField(thisform.DD, COLOR_FIELD_ERROR);
					//window.scrollTo(0, 0);
					markField_MoveDate();
					f_movedateInput.focus();
					msg("The move date cannot be in the past.<br /><br />Please select a valid move date.");
					return false;
				} else {
					// And also compare the hidden date with the actual day visually selected on the calendar
					var $selectedDate = $("td.is-selected button.pika-day"); // SELECTED DAY
					var selectedDate = $selectedDate.length ? moment(createDateString($selectedDate.attr("data-pika-year"), (parseInt($selectedDate.attr("data-pika-month")) + 1), $selectedDate.attr("data-pika-day")), DATE_FORMAT_PIK_UI).format(DATE_FORMAT_YYYYMMDD) : "____-__-__";
					var bDoDatesMatch = (selectedDate == mMoveDateSelected.format(DATE_FORMAT_YYYYMMDD));
					if (!bDoDatesMatch) {
						markField_MoveDate();
						f_movedateInput.focus();
						msg("Sorry, the date selected is invalid, likely due to timezone issues (A0007).<br />Please re-select your Move Date, or change your timezone if the issue persists.", "Error", true);
						//clearPickerSelection();
						return false;
					}
				}
				//TODO: else ... check to ensure that the date is not a disabled day (weekends, holidays, etc)
			}

			// Validate required fields
			for (var i = 0; i < arrRequiredFields.length; i++) {
				// Do not validate Month, Day, and Year (a default selection is acceptable, there is no "Select One" text)
				if ((arrRequiredFields[i] != "MM") && (arrRequiredFields[i] != "DD") && (arrRequiredFields[i] != "YY")) {
					currField = thisform.elements[arrRequiredFields[i]];
					checkField(currField);
				}
			}
			// If error, focus on the first error field and show message
			if (g_firstErrorFieldName !== "") {
				thisform.elements[g_firstErrorFieldName].focus();
				msg("Please complete all required fields.");
				return false;
			}

			// Validate the Email Address
			var f_email = thisform.elements["email"];
			if (!validateEmail(f_email.value)) {
				markField(f_email);
				f_email.focus();
				msg("Please enter a valid email address.");
				return false;
			}

			// Validate the Email Address Confirmation
			var f_email_confirm = thisform.elements["emailconfirm"];
			if (!validateEmail(f_email_confirm.value)) {
				markField(f_email_confirm);
				f_email_confirm.focus();
				msg("Please enter a valid email address for confirmation.");
				return false;
			}

			// Confirm Email Address (Highligh confirmation if it does not match original email entered)
			if (f_email.value !== f_email_confirm.value) {
				markField(f_email_confirm);
				f_email_confirm.focus();
				msg("Please confirm your email address (they must match).");
				return false;
			}

			// Validate the Postal Codes (length of 6 only)
			var f_orig_postal = thisform.elements["OrigPostal"];
			if (f_orig_postal.value.replace(" ", "").length !== 6) {
				markField(f_orig_postal);
				f_orig_postal.focus();
				msg("Please enter a valid postal code for the origin location.");
				return false;
			}
			var f_dest_postal = thisform.elements["DestPostal"];
			if (f_dest_postal.value.replace(" ", "").length !== 6) {
				markField(f_dest_postal);
				f_dest_postal.focus();
				msg("Please enter a valid postal code for the destination location.");
				return false;
			}

			// Make sure that either furniture or boxes are specified
			var f_furnyTA = thisform.elements["Furny"];
			var f_smBoxes = thisform.elements["SmBoxes"];
			var f_mdBoxes = thisform.elements["MdBoxes"];
			var f_lgBoxes = thisform.elements["LgBoxes"];
			var f_wrBoxes = thisform.elements["WRBoxes"];
			var f_smBins = thisform.elements["SmBins"];
			var f_mdBins = thisform.elements["MdBins"];
			var f_lgBins = thisform.elements["LgBins"];
			var f_bags = thisform.elements["Bags"];
			var f_nofurny = thisform.elements["NoFurniture"];
			var f_noboxes = thisform.elements["NoBoxesOrBins"];
			if (f_nofurny.checked && f_noboxes.checked) {
				markField(f_furnyTA);
				f_furnyTA.focus();
				msg("You must list either furniture or boxes to be moved.");
				return false;
			} else if (!f_nofurny.checked && f_furnyTA.value.trim().length == 0) {
				markField(f_furnyTA);
				f_furnyTA.focus();
				msg("You must list furniture to be moved, or check the box saying you have no furniture to be moved");
				return false;
			} else if (!f_noboxes.checked && (f_smBoxes.value.trim().length == 0) && (f_mdBoxes.value.trim().length == 0) && (f_lgBoxes.value.trim().length == 0) && (f_wrBoxes.value.trim().length == 0) && (f_smBins.value.trim().length == 0) && (f_mdBins.value.trim().length == 0) && (f_lgBins.value.trim().length == 0) && (f_bags.value.trim().length == 0)) {
				markField(f_smBoxes);
				f_smBoxes.focus();
				msg("You must list boxes to be moved, or check the box saying you have no boxes to be moved");
				return false;
			}

			// No problems if this point is reached, submit the form
			return true;

		} catch (ex) {
			// Do not submit the form
			alert("Sorry, we cannot process your request due to an error.\n\nPlease reload the page and try again.");
			debug_to_console_js("VALIDATION ERROR: " + ex.message + " >>> " + ex.stack);
			return false;
		}
	}

	// Validate an email address
	function validateEmail(value) {
		var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailPattern.test(value);
	}

	// Highlight a field
	function checkField(f) {
		// check if each required field has a value/selection
		switch (f.type.toLowerCase()) {
			case "text":
			case "email":
				if (f.value == "") { setErrorField(f) } else { unmarkField(f) }
				break;
			case "textarea":
				if (f.value == "") { setErrorField(f) } else { unmarkField(f) }
				break;
			case "checkbox":
				if (!f.checked) { setErrorField(f) } else { unmarkField(f) }
				break;
			case "select-one":
				if (!f.selectedIndex && f.selectedIndex == 0) { setErrorField(f) } else { unmarkField(f) }
				break;
		}
	}
	// Handle an error in a field
	function setErrorField(field) {
		if (g_firstErrorFieldName == "") {
			g_firstErrorFieldName = field.name;  // Only set the first error field on encountering the first error
		}
		markField(field);
	}
	// Handle no error in the date field (back to transparent)
	function unmarkField_MoveDate() {
		$g_movedateInput.removeClass(VALIDATION_ERROR_CLASS_MOVEDATE);
	}
	// Highlight Move Date (how they all should work)
	function markField_MoveDate() {
		$g_movedateInput.addClass(VALIDATION_ERROR_CLASS_MOVEDATE);
	}
	// Clear validation flagging
	function unmarkField(field) {
		$(field).removeClass(VALIDATION_ERROR_CLASS);
	}
	// Highlight a field that failed validation
	function markField(field, color) {
		$(field).addClass(VALIDATION_ERROR_CLASS);

		//field.style.backgroundColor = color;

		// These don't actually work to force redraws (vs browser waiting until it's idle AFTER showing the dialog that follows)
		//$(field).trigger("redraw",this);
		//$(field).redraw();
		// And to test them, add these to the end of page's scripts or on load/ready
		// // Redraw method to update UI elements during script flow
		// $(":input").on("redraw",function(element) {
		// 	alert("go away!" + $(element)[0].target.name);
		// 	var n = document.createTextNode(" ");
		// 	$(element)[0].target.appendChild(n);
		// 	//(function(){n.parentNode.removeChild(n)}).defer();
		// 	n.parentNode.removeChild(n);
		// 	return $(element);
		// });

		// $g_movedate.addMethods({
		// 	redraw: function(element){
		// 		element = $(element);
		// 		var n = document.createTextNode(" ");
		// 		element.appendChild(n);
		// 		(function(){n.parentNode.removeChild(n)}).defer();
		// 		return element;
		// 	}
		// });
	}
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CORE ESTIMATE FORM LOGIC
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// Scroll the start of the estimate form into view
	// ES6: function scrollToEstimateForm(bInstant = false) {
	function scrollToEstimateForm(bInstant) {
		bInstant = bInstant || false;
		//HOOK: Get any scroll adjustment (e.g. fi stylepicker is loaded)
		var scrollAdjustment = (typeof hook_get_scoll_adjustment === "function") ? hook_get_scoll_adjustment() : 0;
		// Animate the scroll to the top of the form
		var animationTime = bInstant ? 0 : 1000;
		$('html, body').stop(true, true).animate({
			scrollTop: $("#EstimateForm").offset().top - scrollAdjustment
		}, animationTime, function () {
			// After scrolling, test for datepicker (only after scrolling else message may be offscreen)
			if (typeof g_MoveDatePicker === "undefined") {
				msg("Unable to load the calendar (B0001).<br />Please clear the cache and reload the page.", "Error", true);
			} else {
				// Focus on the move date (even if manual entry is not enabled)
				try { $g_movedateInput[0].focus(); } catch (ex) { }
			}
		});
	}

	// RENDER PIKADAY CALENDAR for Move Date selection (only once booking data has been retrieved)
	function renderCalendar() {
		try {

			// Min and Max date must be JS dates and must be based in NY/EST for these to work properly in Ottawa time (NOTE: must clone as moments are mutable)
			var CAL_JS_MIN_DATE = getCalendarMinDateForDefaultTimezone();										// FROM: today (EST) moment.toDate drops timezone so must use timezone-adusted moment
			var CAL_JS_MAX_DATE = MOM_TODAY_EST.clone().add(CAL_YEARS_TO_ALLOW, "years").toDate();		// TO:	until {CAL_YEARS_TO_ALLOW=3} years from now

			if ($g_movedate.length) {
				// Create the calendar
				g_MoveDatePicker = pikadayResponsive($g_movedate, {
					format: DATE_FORMAT_DISPLAY,			// e.g. "November 3rd, 2019"																					// DEFAULT: "YYYY-MM-DD"
					outputFormat: DATE_FORMAT_OUTPUT,			// if "X" as it was, e.g. 1572753600 = Unix timestamp for 2019-11-03 (without msecs) 		// DEFAULT: "YYYY-MM-DD"
					dayOffset: CAL_DAYS_TO_OFFSET,
					checkIfNativeDate: function () {
						return false; // FALSE = Never use the native datepicker (which would otherwise be used on mobile phones if touch and HTML5-date are supported)
						// NOTE: On mobile browsers, PikadayResponsive was falling back to native datepicker regardless (dropdown with MM,DD,YYYY scrollers / mobile-standard)
						// NOTE: MoveDate (datepicker field) had to be visible in order for the native datepicker to render (use media selectors or base on modernizr/screen width)
						// TODO-TEST: I don't believe the native datepicker overrides now even if the "date"-type input is visible ... test it
					},
					pikadayOptions: {
						// BINDING
						// NOW ALWAYS VISIBLE / UNBOUND AND NO CONTAINER (BEST THIS WAY, BUT DID TRY container="pikadayContainer", bound=false)
						//field: document.getElementById(MOVE_DATE_FIELD_NAME),	// 'MoveDate'
						bound: false,
						//container: document.getElementById('pikadayContainer'),

						// ACCESSIBILITY FOR SCREENREADER NAV (FALSE DOES NOT DISABLE MANUAL INPUT SO FOR THAT I HAD TO USE readonly FLAG ON MoveDate placeholder)
						keyboardInput: true,

						// CONFIG
						// minDate, MaxDate **should** be native date object, according to Pikaday docs (true, the code only takes JS Date and strips important time date.setHours(0, 0, 0, 0))
						firstDay: WEEKDAYS.SUNDAY,
						minDate: CAL_JS_MIN_DATE,
						maxDate: CAL_JS_MAX_DATE,
						numberOfMonths: MONTHS_AT_ONCE,

						// Add customizations when pikaday opens (will happen only once when unbound / rendered always visible in a container)
						onOpen: function () {
							try {
								// Hide the preloader
								//$("div#pikaday-loading-frm").removeClass("pikaday-show-loader");
								$("div#pikaday-loading-frm").hide();
								// MOVED FROM ONDRAW DUE TO SYLE/POSITION CHANGE:  Add legend to pikaday control (must be done here in onOpen, or else subsequent draws will drop the legend)
								addLegendtoPikaday();
								// Pikaday creates a new input field for it's calendar ([FIELDNAME]-input)
								$g_movedateInput = $("#" + MOVE_DATE_INPUT_FIELD_NAME);
								// Disable manual input now that it is available (done only in JQuery so if Javascript is disabled the user can still input)
								$g_movedateInput.prop("readonly", true);
								// Fix the tab order by setting this to tabindex 2 (movedate was 1, others are greater than 2)
								$g_movedateInput.prop("tabindex", 2);
								// Handle date changes (runs for select or manual entry, though manual entry was tested it is disabled at this time)
								$g_movedate.on("change", function () {		// ALSO COULD USE JS DATE WITH: $g_movedate.on("change-date", function() {
									handleMoveDateChange(this);
								});
								$g_movedateInput.attr('placeholder', 'To start, first select a date')

								// Prevent form submission when user types a date and hits enter (in the Pikaday-generated field)
								$g_movedateInput.on("keyup keypress", function (e) {
									// NOTE: Since disabling enter altogether, trigger blur to force pikaday update (could do only if date is valid and call setDate, but pikaday handles that mostly)
									// IMPORTANT - Manual entry is no longer permitted (but leaving this active just in case manual entry required in future, change event validation would be required)
									var keyCode = e.keyCode || e.which;
									if (keyCode === 13) {
										e.preventDefault();
										// Force the control to handle the update as setDate (g_MoveDatePicker.setDate($g_movedate.val());) can cause some crazy validation / dialog loops
										$(this).blur().focus();
										return false;
									}
								});
							} catch (ex) {
								msg("Unable to load the calendar (A0004).<br />Please clear the cache and reload the page.", "Error", true);
								error_to_console_js("OPEN ERROR: " + ex.message + " >>> " + ex.stack);
							}

						},

						// HIGHLIGHT DAYS
						// When the picker draws a new set of {MONTHS_AT_ONCE} months:
						onDraw: function (drawn) {
							try {
								// Before doing anything else, override Pikaday's is-today (isToday) with the application timezone date
								$("td.is-today").removeClass("is-today");
								$("td:has(button[data-pika-year='" + parseInt(MOM_TODAY_EST.format("YYYY")) + "'][data-pika-month='" + (parseInt(MOM_TODAY_EST.format("MM")) - 1) + "'][data-pika-day='" + parseInt(MOM_TODAY_EST.format("DD")) + "'])").addClass("is-today");

								// Get the classes used for highlighting
								var classHolidays = (typeof hook_get_class_holidays === "function") ? hook_get_class_holidays(g_CALSTYLE) : "frm-is-faded";
								var classClosedBooked = (typeof hook_get_class_closed_or_booked === "function") ? hook_get_class_closed_or_booked(g_CALSTYLE) : "frm-is-faded";
								var classPeakRate = (typeof hook_get_class_peakrate === "function") ? hook_get_class_peakrate(g_CALSTYLE) : "frm-is-peakrate-1";
								var classMidRate = (typeof hook_get_class_midrate === "function") ? hook_get_class_midrate(g_CALSTYLE) : "frm-is-midrate-1";
								var classBaseRate = (typeof hook_get_class_baserate === "function") ? hook_get_class_baserate(g_CALSTYLE) : "frm-is-baserate-1";
								// Did not add small moves to the style picker hooks, just a quick update (04SEP2020)
								var classSmallMovesOnly = (typeof hook_get_class_TODO === "function") ? hook_get_class_TODO(g_CALSTYLE) : "frm-is-smallmoves-1";
								var classDefault = (typeof hook_get_class_buttons === "function") ? hook_get_class_buttons(g_CALSTYLE) : "frm-is-centered-1";
								var selector, classToAdd;

								// Fade all buttons unless the styles override it (CALSTYLE 1 does override, default to centered-1 instead)
								selector = "td button.pika-day";
								classToAdd = classDefault;
								$(selector).addClass(classToAdd);

								// Now do the other rendering...
								for (var cal = 0; cal < this.calendars.length; cal++) {

									var drawnMonth = this.calendars[cal].month + 1;
									var drawnYear = this.calendars[cal].year;

									// LOOP THROUGH THE MONTH AND HIGHLIGHT BASED ON EACH DAY
									var mLoopDate = moment(createDateString(drawnYear, drawnMonth, "01"), DATE_FORMAT_YYYYMMDD);
									var daysInMonth = mLoopDate.daysInMonth();
									debug_to_console_js("DAYS IN " + mLoopDate.format("MMMM YYYY") + ": " + daysInMonth);

									for (var i = 1; i <= daysInMonth; i++) {
										//debug_to_console_js("DAY: " + createDateString(drawnYear, drawnMonth, i) + " ~ " + mLoopDate.format(DATE_FORMAT_YYYYMMDD));
										//debug_to_console_js("mom()       - IS BEFORE TODAY? " + mLoopDate.format(DATE_FORMAT_DEBUG) + " < " + moment().format(DATE_FORMAT_DEBUG) + " ===> " + mLoopDate.isBefore(moment(),"day"));
										//debug_to_console_js("      TODAY - IS BEFORE TODAY? " + mLoopDate.format(DATE_FORMAT_DEBUG) + " < " + MOM_TODAY_EST.format(DATE_FORMAT_DEBUG) + " ===> " + isMomentBeforeToday(mLoopDate));
										//debug_to_console_js("isMomentBeforeToday(" + mLoopDate.format(DATE_FORMAT_DEBUG) + ") = " + isMomentBeforeToday(mLoopDate));

										// Prepare selectors for each day
										let selectorEnabled = "button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth - 1) + "'][data-pika-day='" + i + "']";
										let selectorDisabled = "td[class!='is-disabled'] button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth - 1) + "'][data-pika-day='" + i + "']";

										// debug_to_console_js(mLoopDate.format(DATE_FORMAT_YYYYMMDD) + " - small moves?   " + (isOverride(mLoopDate,"SMALLMOVES")?"yes":"no") );

										// OVERRIDES - If OVERRIDE:CLOSED/BOOKED then closed/faded, else if OVERRIDE:RATE that rate, else if HOLIDAY/CLOSED AND NOT OVERRIDE:OPEN then holiday/closed, else logic rates
										if (!g_DO_NOT_DISABLE_DAYS && (isOverride(mLoopDate, "CLOSED") || isOverride(mLoopDate, "BOOKED"))) {
											selector = selectorEnabled;
											classToAdd = classClosedBooked;
										} else if (isOverride(mLoopDate, "PEAKRATE")) {
											selector = selectorDisabled;
											classToAdd = classPeakRate;
										} else if (isOverride(mLoopDate, "MIDRATE")) {
											selector = selectorDisabled;
											classToAdd = classMidRate;
										} else if (isOverride(mLoopDate, "BASERATE")) {
											selector = selectorDisabled;
											classToAdd = classBaseRate;
										} else if (!g_DO_NOT_DISABLE_DAYS && (!isOverride(mLoopDate, "OPEN")) && isHoliday(mLoopDate)) {
											selector = selectorEnabled;
											classToAdd = classHolidays;
										} else if (!g_DO_NOT_DISABLE_DAYS && (!isOverride(mLoopDate, "OPEN")) && (isSunday(mLoopDate) || (isMonday(mLoopDate) && (!isTheOpenMonday(mLoopDate))) || (isMomentBeforeToday(mLoopDate)))) {
											selector = selectorEnabled;
											classToAdd = classClosedBooked;
										} else if (isDayAtPeakRate(mLoopDate)) {
											selector = selectorDisabled;
											classToAdd = classPeakRate;
										} else if (isDayAtMidRate(mLoopDate)) {
											selector = selectorDisabled;
											classToAdd = classMidRate;
										} else {
											selector = selectorDisabled;
											classToAdd = classBaseRate;
										}
										$(selector).addClass(classToAdd);

										// 05SEP2020: Small Moves
										if (selector == selectorDisabled) {
											if (isOverride(mLoopDate, "SMALLMOVES")) {
												classToAdd = classSmallMovesOnly;
												$(selector).addClass(classToAdd);
											}
										}

										// OLD LOGIC - START

										// // A) CLOSED HOLIDAYS - COULD DO A LOOP THROUGH A PREDEFINED ARRAY OR DATA SOURCE
										// // NOTE: For now, using the custom array defined in global scope AND this overrides SUN/MON colouring in succession
										// if (!g_DO_NOT_DISABLE_DAYS && isHoliday(mLoopDate)) {
										// 	//debug_to_console_js("holiday: " + mLoopDate.format( DATE_FORMAT_YYYYMMDD ));
										// 	//Highlight the holiday (will have is-disabled attribute but no need to filter)
										// 	//$("button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']").css("cssText","color: #cccccc !important;background-color: #cc3333 !important;opacity: 0.5 !important;");
										// 	selector = "button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']";
										// 	//$(selector).css("cssText", holidayCSS);
										// 	classToAdd = (typeof hook_get_class_holidays === "function") ? hook_get_class_holidays(g_CALSTYLE) : "frm-is-faded";
										// 	$(selector).addClass(classToAdd);

										// // B) CLOSED SUN/MON AND BEFORE TODAY (see if better UX than disabled) * logic copied from my disabled logic but momentized plus dates before today coloured
										// } else if (!g_DO_NOT_DISABLE_DAYS && ((isSunday(mLoopDate)) || ((isMonday(mLoopDate)) && (!isTheOpenMonday(mLoopDate))) || (isMomentBeforeToday(mLoopDate)))) {
										// 	selector = "button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']";
										// 	//$(selector).css("cssText", closedCSS);
										// 	classToAdd = (typeof hook_get_class_closed_or_booked === "function") ? hook_get_class_closed_or_booked(g_CALSTYLE) : "frm-is-faded";
										// 	$(selector).addClass(classToAdd);

										// // B2) BOOKED DAYS NOW MARKED AS CLOSED AS WELL (so it is not fully stated that they are booked, maybe closed aka "booked" in Google Calendar for other reasons too)
										// } else if (!g_DO_NOT_DISABLE_DAYS &&  _.indexOf( GCAL_OVERRIDES, mLoopDate.format( DATE_FORMAT_YYYYMMDD ) ) !== -1 ) {
										// 	selector = "button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']";
										// 	//$(selector).css("cssText", closedCSS);
										// 	classToAdd = (typeof hook_get_class_closed_or_booked === "function") ? hook_get_class_closed_or_booked(g_CALSTYLE) : "frm-is-faded";
										// 	$(selector).addClass(classToAdd);

										// // C) PEAK RATES
										// } else if (isDayAtPeakRate(mLoopDate)) {
										// 	//debug_to_console_js("button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']");
										// 	//Highlight the day only if it is not disabled
										// 	//$("td[class!='is-disabled'] button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']").css("cssText","color: #cccccc;background-color: #0c3b72 !important;opacity: 0.5 !important;");
										// 	//$("td[class!='is-disabled'][class!='is-selected'] button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']").css("cssText","color: #cccccc;background: repeating-linear-gradient(-55deg,#606dbc,#606dbc 10px,#465298 10px,#465298 20px) !important;opacity: 0.5 !important;");
										// 	// NOTE: Now that the rate styles work well with the is-selected class, no longer need [class!='is-selected']
										// 	selector = "td[class!='is-disabled'] button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']";
										// 	//$(selector).css("cssText", peakrateCSS);
										// 	// Only add the extra style if the loop date is not the date currently selected on the calendar (because calendar redraws after selection, so let selected style take effect)
										// 	// mLoopDate.diff(this.getDate(),"days") == 0 no good as it can return NaN and isSame is not for this use case
										// 	//if (mLoopDate.format(DATE_FORMAT_YYYYMMDD) !== this.toString(DATE_FORMAT_YYYYMMDD))
										// 	classToAdd = (typeof hook_get_class_peakrate === "function") ? hook_get_class_peakrate(g_CALSTYLE) : "frm-is-peakrate-1";
										// 	$(selector).addClass(classToAdd);
										// 	//debug_to_console_js("*********************** PEAK DATE");

										// // D) MID RATES
										// } else if (isDayAtMidRate(mLoopDate)) {
										// 	// NOTE: Now that the rate styles work well with the is-selected class, no longer need [class!='is-selected']
										// 	selector = "td[class!='is-disabled'] button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']";
										// 	//$(selector).css("cssText", midrateCSS);
										// 	// Only add the extra style if the loop date is not the date currently selected on the calendar (because calendar redraws after selection, so let selected style take effect)
										// 	// mLoopDate.diff(this.getDate(),"days") == 0 no good as it can return NaN and isSame is not for this use case
										// 	//if (mLoopDate.format(DATE_FORMAT_YYYYMMDD) !== this.toString(DATE_FORMAT_YYYYMMDD)) $(selector).addClass("frm-is-midrate");
										// 	classToAdd = (typeof hook_get_class_midrate === "function") ? hook_get_class_midrate(g_CALSTYLE) : "frm-is-midrate-1";
										// 	$(selector).addClass(classToAdd);
										// 	//debug_to_console_js("*********************** MID DATE");

										// } else {
										// 	selector = "td[class!='is-disabled'] button[data-pika-year='" + drawnYear + "'][data-pika-month='" + (drawnMonth-1) + "'][data-pika-day='" + i + "']";
										// 	// Change all other available days to base rate
										// 	classToAdd = (typeof hook_get_class_baserate === "function") ? hook_get_class_baserate(g_CALSTYLE) : "frm-is-baserate-1";
										// 	$(selector).addClass(classToAdd); // if null thats okay and better perf than testing for null
										// 	//debug_to_console_js("*********************** BASE DATE");
										// }

										// OLD LOGIC - END

										mLoopDate.add(1, "days");
									}
								}
							} catch (ex) {
								msg("Unable to load the calendar (A0005).<br />Please clear the cache and reload the page.", "Error", true);
								error_to_console_js("DRAW ERROR: " + ex.message + " >>> " + ex.stack);
							}

							//HOOK: DEBUG HOOK 2 - Helper panel for debugging variables and issues (any time the calendar is redrawn, including first draw when is-today may be corrected)
							if (typeof hook_debug_date_and_timezones === "function") { hook_debug_date_and_timezones("pikaday-ondraw-end"); }
						},

						// BLOCK DAYS
						disableDayFn: function (jsDate) {
							try {

								if (!g_DO_NOT_DISABLE_DAYS) {

									// Convert the localized zero-hour JS Date for each pikaday to an EST-based zero-hour moment using JUST THE YYYY-MM-DD portion as it is drawing THAT day
									// NOTE: No longer applying the computed dayOffset like PR internals because this moment is created with a JS Date object by Pikaday
									var adjustedDate = getESTAdjustedMomentFromBrowserTZ(jsDate);
									// OVERRIDES - OVERRIDE:CLOSED/BOOKED then disabled, else if OVERRIDE:OPEN/RATE then enabled, else if CLOSED/HOLIDAYS then disabled
									if (isOverride(adjustedDate, "CLOSED") || isOverride(adjustedDate, "BOOKED")) return true;
									if (isOverride(adjustedDate, "OPEN") || isOverride(adjustedDate, "PEAKRATE") || isOverride(adjustedDate, "MIDRATE") || isOverride(adjustedDate, "BASERATE")) return false;
									// CLOSED SUNDAYS AND MONDAYS - Disable all but last Monday of month
									if ((isSunday(adjustedDate)) || ((isMonday(adjustedDate)) && (!isTheOpenMonday(adjustedDate)))) return true;
									// HOLIDAYS - Disable specific dates / feed of holidays from external site / db
									if (isHoliday(adjustedDate)) return true;
								}

							} catch (ex) {
								msg("Unable to load the calendar (A0006).<br />Please clear the cache and reload the page.", "Error", true);
								error_to_console_js("DISABLE ERROR: " + ex.message + " >>> " + ex.stack);
							}
						},

						onSelect: function (jsDate) {
							// Remove any validation style from the field as there is now a date
							unmarkField_MoveDate();
							// If manual entry is allowed again, blur the field either here or handleMoveDateChange
							// $g_movedateInput.blur();
							// IMP: SEE RG CUSTOM CODE IN PIKADAY MIN WHERE I DO MINIMAL REDRAW W/ SELECTION HIGHLIGHTING
						},

					},
				});
			}



			// RG-25SEP2019: Instead of in prepareForm, restoration of submitted date when user navigates BACK on server validation/error MUST be done here AFTER calendar is re-rendered
			// When user navigates back after prior submission, Datepicker loses the selection and the hidden fields will lose any previous date - so restore the date
			// g_MoveDatePicker will be null at the prepareForm stage when navigating BACK to the page as it is recreated in a parallel process (race failure)

			// So, attempt to retrieve the MoveDate from Session Storage in browsers that support it but that do not persist the hidden field changes
			// NOTE: FF is different, see next section
			var dateToRestore = null;
			try { dateToRestore = sessionStorage.getItem(MOVE_DATE_SESSION_STORAGE_KEY); if (dateToRestore) { debug_to_console_js("   - MOVE DATE: Retrieved from storage: " + dateToRestore); } } finally { }
			// Restore from hidden fields if they are available (proven logic vs session fallback)
			if ($.isNumeric($("#MM").val()) && $.isNumeric($("#DD").val()) && $.isNumeric($("#YY").val())) {
				var dateToRestore = createDateString($("#YY").val(), $("#MM").val(), $("#DD").val());
				debug_to_console_js("   - MOVE DATE: Setting date from previous submission via hidden fields: " + dateToRestore + " ...");
				g_DATEWASRESTORED = true;
				g_MoveDatePicker.setDate(moment(dateToRestore, DATE_FORMAT_YYYYMMDD));
			} else {
				if (dateToRestore) {
					// Set the calendar move date using the preserved YYYY-MM-DD string, then remove it as it is one-time use
					debug_to_console_js("   - MOVE DATE: Setting date from previous submission via session storage: " + dateToRestore + " ...");
					g_DATEWASRESTORED = true;
					g_MoveDatePicker.setDate(moment(dateToRestore, DATE_FORMAT_YYYYMMDD));
				}
			}
			// Remove the Session Storage regardless of which technique was used (e.g. if hidden fields are preserved like in the browser, still clear the preserved move date)
			// NOTE: If some browsers persist full state including JS renderings like FireFox, this will not execute so session storage will remain until the browser/tab is closed
			if (dateToRestore) {
				try { sessionStorage.removeItem(MOVE_DATE_SESSION_STORAGE_KEY); debug_to_console_js("   - MOVE DATE: Removed from storage"); } finally { }
			}

			//HOOK: DEBUG HOOK 1 - Helper panel for debugging variables and issues (on first load)
			if (typeof hook_debug_date_and_timezones === "function") { hook_debug_date_and_timezones("pikaday-created"); }

		} catch (ex) {
			msg("Unable to load the calendar (A0002).<br />Please clear the cache and reload the page.", "Error", true);
			error_to_console_js("CALENDAR ERROR: " + ex.message + " >>> " + ex.stack);
		}
	}

	// Get the effective rate for a given date (standard logic + overrides)
	function getEffectiveRate(d) {
		return isOverride(d, "PEAKRATE") ? "peak" : isOverride(d, "MIDRATE") ? "mid" : isOverride(d, "BASERATE") ? "base" :
			isDayAtPeakRate(d) ? "peak" : isDayAtMidRate(d) ? "mid" : "base";
	}
	// Get a list of all overrides for a given date (for storing to DB and including in email)
	function getGCALOverrides(d) {
		return (_.pluck(_.where(GCAL_OVERRIDES, { date: d.format(DATE_FORMAT_YYYYMMDD) }), 'summary').join(", ")); // where() returns multiple matches for a given date
	}
	// Is there an override from Google Calendar for the requested date and override type?
	function isOverride(d, override) {
		return (_.findWhere(GCAL_OVERRIDES, { date: d.format(DATE_FORMAT_YYYYMMDD), summary: override.toLowerCase() })); // returns matched object or undefined, vs .where() which returns multiple matches
	}
	// Is the day a holiday?
	function isHoliday(d) {
		return (_.indexOf(ONTARIO_HOLIDAYS, d.format(DATE_FORMAT_YYYYMMDD), ARRAY_IS_SORTED) !== -1)
	}

	// $("#clear").click(function() {
	//		clearPickerSelection();
	// 	//g_MoveDatePicker.setDate(null);
	// });

	// Add a legend to the Calendar (now only being called once in order to place it below the first pika-table/month but not being part of that flex grid)
	function addLegendtoPikaday() {
		var legendContainerDiv = "<div class='frm-pika-legend-container'></div>";
		var legendBox = "<span class='frm-pika-legend-box'>&nbsp;</span>";
		var legendBoxSmallMoves = "<span class='frm-pika-legend-box'><span class='frm-pika-legend-box-small-moves-text'>0</span></span>";
		var defaultLegendContainer = $(legendContainerDiv)
			.append("&nbsp;&nbsp;")
			.append($(legendBox).addClass("frm-is-baserate-1")).append("&nbsp;<span id=\"frm-legend-rate-base\">Base Rate</span>&nbsp;&nbsp;")
			.append($(legendBox).addClass("frm-is-midrate-1")).append("&nbsp;<span id=\"frm-legend-rate-mid\">Mid Rate</span>&nbsp;&nbsp;")
			.append($(legendBox).addClass("frm-is-peakrate-1")).append("&nbsp;<span id=\"frm-legend-rate-peak\">Peak Rate</span>&nbsp;&nbsp;")
			.append($(legendBoxSmallMoves).addClass("frm-is-smallmoves-1")).append("&nbsp;<span id=\"frm-legend-small-moves\">Small Moves Only</span>")
		var $legendContainer = (typeof hook_get_legend_container === "function") ? hook_get_legend_container(g_CALSTYLE, legendContainerDiv, legendBox, defaultLegendContainer) : defaultLegendContainer;

		//$("table.pika-table").first().after( $legendContainer );
		// A) This was used when drawing the legend each time (ondraw) within the new pikaday calendar layers
		//if (!$("div.frm-pika-legend-container").length) $("div.pika-lendar").last().after($legendContainer);
		// B) This is used when drawing the legend only once (places it aferwards and requires matching styles (B) for LEGEND CONTAINER)
		$("div.pika-single").after($legendContainer);
	}

	// Is a given day available? Closed Sunday/Holidays, Closed Mondays except last of month)
	// NOTE: Blocked before today and after 3 years by disable function
	// NOTE: All dates that are not blocked off are available (could flag as "Contractor" for last monday of month etc)
	//function isDayAvailable(d) { return (d.day() !== 0); }
	// Is a given day during peak period? (Peak: 25th-1st + Saturdays) * Input is a moment
	//NOTE: See "RATES - 08SEP2018" in the datepicker init customization
	//TODO: Add Fridays before long weekends?
	function isDayAtPeakRate(d) { return ((d.date() === 1) || (d.date() >= 25) || (isSaturday(d))); }
	function isDayAtMidRate(d) { return (isFriday(d)); }
	// Day checks (Sunday = 0)
	function isFriday(d) { return (d.day() === WEEKDAYS.FRIDAY); }
	function isSaturday(d) { return (d.day() === WEEKDAYS.SATURDAY); }
	function isSunday(d) { return (d.day() === WEEKDAYS.SUNDAY); }
	function isMonday(d) { return (d.day() === WEEKDAYS.MONDAY); }
	// Is the given date the only open Monday of the month?
	function isTheOpenMonday(d) {
		// NOTE: This will also pick up the last monday of the previous month even though it won"t render in the current month (which means no adverse side affect)
		// NOTE: This is locale-agnostic by using day() instead of weekday() so ALWAYS 0 = SUNDAY, 1 MONDAY, etc
		if (d.day() !== 1) return false;
		// If the given date is the first day of month AND it is a Monday ===> OPEN
		// Else find the last Monday of the month and if that is the given date ===> OPEN
		var momFirstDayOfMonth = d.clone().startOf("month").startOf("day"); 	// Redundant but redundant
		var momLastMondayOfMonth = d.clone().endOf("month").startOf("day"); 		// Otherwise it is the last second of the month, just want the day
		var momTheOpenMonday;
		if (momFirstDayOfMonth.day() === 1) {
			// The Open Monday is the 1st day of the month
			momTheOpenMonday = momFirstDayOfMonth.clone();
		} else {
			// The Open Monday is the last Monday of the month
			while (momLastMondayOfMonth.day() !== 1) momLastMondayOfMonth.subtract(1, "days");
			momTheOpenMonday = momLastMondayOfMonth.clone();
		}
		//debug_to_console_js("[B] THE OPEN MONDAY IS [" + momTheOpenMonday.format( DATE_FORMAT_YYYYMMDD ) + "]");
		// Is the given day The Open Monday?
		return (momTheOpenMonday.isSame(d, "day"));
	}
	function isMomentBeforeToday(d) {
		return d.isBefore(MOM_TODAY_EST, "day");
	}

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const is_preview = urlParams.get('ottawamovingestimate-preview') ?? 0;

	$('.ottawamovingestimate-form').each(function () {

		let thisForm = $(this)

		thisForm.find('.gform-footer .gform_button').attr('disabled', true)

		if (!thisForm.closest('.gform_wrapper').hasClass('gform_validation_error')) {
			eraseCookie('ottawamovingestimate_DD')
			eraseCookie('ottawamovingestimate_MM')
			eraseCookie('ottawamovingestimate_YY')
			$("#MM").val("");
			$("#DD").val("");
			$("#YY").val("");
			thisForm.find('.gform-footer .gform_button').attr('disabled', true)
			thisForm.find('.gfield:not(.gfield--type-ottawamovingestimate)').find('.ginput_container').addClass('gfield-empty')
		}

		if (getCookie('ottawamovingestimate_YY')) {
			$("#MM").val(getCookie('ottawamovingestimate_MM'));
			$("#DD").val(getCookie('ottawamovingestimate_DD'));
			$("#YY").val(getCookie('ottawamovingestimate_YY'));
			thisForm.find('.gfield--type-ottawamovingestimate .validation_message').fadeOut(0);
			thisForm.find('.gform-footer .gform_button').attr('disabled', false)
			if( thisForm.find('.gfield:not(.gfield--type-ottawamovingestimate)').find('.ginput_container').hasClass('gfield-empty') ){
				thisForm.find('.gfield:not(.gfield--type-ottawamovingestimate)').find('.ginput_container').removeClass('gfield-empty');
			}
		}

		if (thisForm.closest('.gform_wrapper').hasClass('gform_validation_error') && !$(this).find('.gfield--type-ottawamovingestimate #YY').val()) {
			thisForm.find('.gfield--type-ottawamovingestimate .validation_message').fadeIn(0);
		}

		if (is_preview) {
			thisForm.find('.gfield--type-ottawamovingestimate .validation_message').fadeOut(0);
			thisForm.addClass('ottawamovingestimate-preview')

			let ottawamovingestimateData = localStorage.getItem('ottawamovingestimateData');
			if (ottawamovingestimateData) {

				let formDataObj = JSON.parse(ottawamovingestimateData);

				Object.keys(formDataObj).forEach(function (name) {
					let value = formDataObj[name];

					if(value){
						if( thisForm.find('[name="' + name + '"]').attr('type') === 'checkbox' ){
							thisForm.find('[name="' + name + '"]').prop( "checked", true )
						}
						else{
							thisForm.find('[name="' + name + '"]').val(value).closest('.gfield').find('.gfield_label').fadeOut(0);
						}
					}
				})

				if( thisForm.find('.gfield:not(.gfield--type-ottawamovingestimate)').find('.ginput_container').hasClass('gfield-empty') ){
					thisForm.find('.gfield:not(.gfield--type-ottawamovingestimate)').find('.ginput_container').removeClass('gfield-empty');
				}

				thisForm.find('.gform-footer .gform_button').attr('disabled', false)
				history.pushState({}, '',location.origin + location.pathname)

				// thisForm.find('.gform-footer .gform_button.button').fadeOut(0)
				// thisForm.closest('.gform_wrapper').append(`<a class="back-to-form" href="https://www.firstratemovers.com/test-form-page/">Back to form</a>`)
			}
		}

		thisForm.append(`<div class="submit-once"><p>Please only press SUBMIT once. It may take a few moments to process.</p></div>`);

		thisForm.find('.gfield').on('click', function(){
			if( $(this).find('.ginput_container').hasClass('gfield-empty') ){
				thisForm.find('.gfield--type-ottawamovingestimate .validation_message').fadeIn(0);
			}
		})
	})

	$('.ottawamovingestimate-form .gfield .ginput_container input, .ottawamovingestimate-form .gfield .ginput_container textarea, .ottawamovingestimate-form .gfield .ginput_container select').each(function () {
		if ($(this).val()) {
			$(this).closest('.gfield').find('.gfield_label').fadeOut(0)
		}
	})

	$('.ottawamovingestimate-form .gfield .ginput_container input, .ottawamovingestimate-form .gfield .ginput_container textarea').on('focusin', function () {
		$(this).closest('.gfield').find('.gfield_label').fadeOut(0)
	}).on('focusout', function () {
		if (!$(this).val()) {
			$(this).closest('.gfield').find('.gfield_label').fadeIn(0)
		}
	})

	$('.ottawamovingestimate-form .gfield .ginput_container select').on('change', function () {
		if ($(this).val()) {
			$(this).closest('.gfield').find('.gfield_label').fadeOut(0)
		}
		else {
			$(this).closest('.gfield').find('.gfield_label').fadeIn(0)
		}
	})

	function getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	function eraseCookie(name) {
		document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	$('form.ottawamovingestimate-form').on('submit', function (e) {
		e.preventDefault();

		let formData = $(this).serializeArray();
		let formObj = {};

		$.each(formData, function (i, field) {
			formObj[field.name] = field.value;
		});

		localStorage.setItem('ottawamovingestimateData', JSON.stringify(formObj));

		this.submit();
	});

	// Handle change of Move Date (prepare related fields and act upon selected date where applicable)
	function handleMoveDateChange(f_HandleMoveDate_UNUSED) {
		debug_to_console_js("   - MOVE DATE: handleMoveDateChange() fired by user selection, browser-back-auto-restore or browser-back-custom-restore");
		// Populate the legacy MM DD and YY fields so the form can process and store the data as it always has
		// OLD UNIX TIMESTAMP APPROACH ("X" format) *** No longer using the parameter as it's safest to use the picker value directly)
		// NOTE: {this} param gets hidden $g_movedate ("MoveDate") as Ticks/"Invalid Date" (if number then valid) - alternatively $("#MoveDate-input").val()="19 Oct 2019"/"Invalid Date"
		//if ($.isNumeric($(f_HandleMoveDate).val())) {
		//	var mMoveDateSelected = moment.unix($(f_HandleMoveDate).val());
		// NEW DATE STRING APPROACH ("YYYY-MM-DD" format)
		// NOTE: {this} param gets hidden $g_movedate ("MoveDate") as outputFormat["YYYY-MM-DD"]/"Invalid Date" (if not "Invalid Date" then valid)
		//			g_MoveDatePicker.value is same but safer [also string "YYYY-MM-DD" (e.g. "2020-01-16")], but could be null (no date set), "Invalid date", or "YYYY-MM-DD"
		if (g_MoveDatePicker.value && (g_MoveDatePicker.value.toLowerCase() !== INVALID_DATE.toLowerCase())) {

			var mMoveDateSelected = moment(g_MoveDatePicker.value, DATE_FORMAT_OUTPUT);

			// If the date selected is a disabled date, deselect it (usually only possible by calling setDate directly in JS, but possible due to potential timezone bug in future)
			try {
				// NOTE: By the time the code gets here, pikaday will already have selected "today" based on the JS DATE if the date selected/set was prior to "today" (so this is more for future unavailable days)
				// NOTE: The selected date must be visible as this test is only applicable for drawn dates (pikaday will move to the selected date on setDate/selection)
				if ($("td.is-disabled:has(button[data-pika-year='" + parseInt(mMoveDateSelected.format("YYYY")) + "'][data-pika-month='" + (parseInt(mMoveDateSelected.format("MM")) - 1) + "'][data-pika-day='" + parseInt(mMoveDateSelected.format("DD")) + "'])").length) {
					msg("The selected date is not available [" + mMoveDateSelected.format(DATE_FORMAT_DISPLAY) + "].<br />Please select another move date.", "Move Date Unavailable", true);
					clearPickerSelection();
					return;
				}
			} catch (ex) { }

			// MOSTLY TO PREVENT ISSUES ON RELOAD IN FF/EDGE THAT RELOAD FORM DATA AND FIRE EVENTS ON REFRESH ... ONLY DO SOMETHING IF THE DATE CHANGED
			//RG-12JAN2019: FIXED THIS LOGIC TO MAKE SURE WARNING IS SHOWN WHEN REQUIRED
			//		Split the logic so IF THE CALENDAR IS DIFFERENT THAN HIDDENS, IT (A) UPDATES MOVEDATE HIDDENS ... ELSE / ALWAYS (B) HIGHLIGHTS LEGEND RATE and (C) UPDATES GCAL HIDDENS
			// 	TEST CASES (DIFFER = HIDDENS different than PIKADAY):
			// 		1) DIFFER		A,B,C,D,E	first date selection (hiddens blank)
			//			2) DIFFER		A,B,C,D,E	subsequent date selection (hiddens differ)
			//			3) DIFFER		A,B,C,D		back - restored from session (most browsers where hiddens are NOT preserved so hiddens differ/blank)
			//			4) SAME			  B,C,D		back - restored from hiddens (FF/Edge) ... fires handler twice? (once from browser restore so hiddens same, once from custom restore so hiddens differ?)
			//													* for coded reload from hiddens should test if different before executing that code (see "Setting date from previous submission via hidden fields")
			//		NOW: Does (4) FIRE TWICE?

			// If the new date is different than the hidden fields (date actually changed)
			if (($("#MM").val() !== mMoveDateSelected.format("MM")) || ($("#DD").val() !== mMoveDateSelected.format("DD")) || ($("#YY").val() !== mMoveDateSelected.format("YYYY"))) {
				// A) Set the hidden fields (used on submission)
				debug_to_console_js("HIDDEN DATE, BEFORE UPDATING HIDDEN FIELDS TO [" + mMoveDateSelected.format(DATE_FORMAT_YYYYMMDD) + "]: " + createDateString($("#YY").val(), $("#MM").val(), $("#DD").val()));
				$("#MM").val(mMoveDateSelected.format("MM"));
				$("#DD").val(mMoveDateSelected.format("DD"));
				$("#YY").val(mMoveDateSelected.format("YYYY"));

				setCookie('ottawamovingestimate_YY', mMoveDateSelected.format("YYYY"), 1);
				setCookie('ottawamovingestimate_MM', mMoveDateSelected.format("MM"), 1);
				setCookie('ottawamovingestimate_DD', mMoveDateSelected.format("DD"), 1);

				$('.ottawamovingestimate-form').each(function () {
					$(this).find('.gform-footer .gform_button').attr('disabled', false)
					$(this).find('.gfield--type-ottawamovingestimate .validation_message').fadeOut(0);
					if($(this).find('.gfield:not(.gfield--type-ottawamovingestimate)').find('.ginput_container').hasClass('gfield-empty')){
						$(this).find('.gfield:not(.gfield--type-ottawamovingestimate)').find('.ginput_container').removeClass('gfield-empty')
					}
				})

				debug_to_console_js("HIDDEN DATE, AFTER UPDATING HIDDEN FIELDS TO [" + mMoveDateSelected.format(DATE_FORMAT_YYYYMMDD) + "]: " + createDateString($("#YY").val(), $("#MM").val(), $("#DD").val()));
			}

			// B) Highlight selected rate in legend
			highlightLegendRate(mMoveDateSelected);

			// C) Populate the rate, and any rate/availability overrides from Google Calendar into hidden fields (for storing to database and using in email)
			captureMoveDateDetails(mMoveDateSelected);

			// D) Highlight the date, Focus on First Name
			//Flash-highlight the movedate input/label
			$g_movedateInput.stop(true, true).switchClass("defaultColour", "highlight", 100).delay(100).switchClass("highlight", "defaultColour", 1000);
			//$g_movedateInput.effect("highlight", {color:"#33aaff"}, 1000);
			// Set focus to FirstName if it is still blank (first time through)
			if (!$("#FirstName").val()) $("#FirstName").focus(); // else $g_movedateInput.focus();

			// E) 60 DAY WARNING: If within the next 60 days, tell them to call
			// ROD: 02FEB2020 - Matt wants no popup shown in this case (but leavingn other logic for suppression in case this gets restored)
			/*
			if (mMoveDateSelected.diff(MOM_TODAY_EST, "days") <= CAL_CALL_WITHIN_X_DAYS) {
				// Only show the message after restoring values during back button navigation OR if the debugging flag does not suppress
				if (!g_SUPPRESS60DAYWARNING) {
					if (!g_DATEWASRESTORED) {
						msg("Please call us to confirm availability before submitting your request,<br />as you are planning to move within the next " + CAL_CALL_WITHIN_X_DAYS + " days.", "Have you called us yet?", true);
					}
					g_DATEWASRESTORED = false;		// Reset the state after processing
				}
			}
			*/

			// F) BLOCK SMALL MOVE DATES FOR 2+ BEDROOM ORIGINS (with message from Matt)		* MAY 2021
			//RG-MAY2021-1/2
			blockBigMovesOnSmallMoveDays(mMoveDateSelected);

		} else {
			clearPickerSelection();
		}
	}

	function setCookie(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	function clearPickerSelection() {
		// Clear the datepicker
		g_MoveDatePicker.setDate(null);
		// Clear the hidden fields used on submission
		$("#MM").val("");
		$("#DD").val("");
		$("#YY").val("");

		$('.ottawamovingestimate-form').each(function () {
			$(this).find('.gform-footer .gform_button').attr('disabled', true)
			$(this).find('.gfield--type-ottawamovingestimate .validation_message').fadeIn(0);
		})
	}

	// Highlight the rate in the legend corresponding to the selected move date
	function highlightLegendRate(d) {
		if (d) {
			// OVERRIDES TRUMP IN SEQUENCE (highest rate prevails), ELSE DEFAULT RATE
			var rateToSelect = getEffectiveRate(d);
			var rateClass = (typeof hook_get_legend_rate_class === "function") ? hook_get_legend_rate_class(g_CALSTYLE) : "is-selected-rate-orange";
			//debug_to_console_js("SELECTOR: " + rateSelector);
			$("div.frm-pika-legend-container span." + rateClass).removeClass(rateClass);
			$("div.frm-pika-legend-container span#frm-legend-rate-" + rateToSelect).addClass(rateClass);

			// SMALL MOVES: If it is a date for only small moves then also update that legend item
			var smallDateClass = "is-selected-day-small-moves-red";
			if (isOverride(d, "SMALLMOVES")) {
				$('.ottawamovingestimate-form .number-of-bedrooms').each(function () {
					$(this).find('select option').each(function (index) {
						if (index > 2) {
							$(this).css('display', 'none');
						}
					})

					$(this).find('select').val('').trigger('change');
				})
				$("div.frm-pika-legend-container span#frm-legend-small-moves").addClass(smallDateClass);
			} else {
				$('.ottawamovingestimate-form .number-of-bedrooms').each(function () {
					$(this).find('select option').each(function (index) {
						if (index > 2) {
							$(this).css('display', 'block');
						}
					})
				})
				$("div.frm-pika-legend-container span#frm-legend-small-moves").removeClass(smallDateClass);
			}

			let ottawamovingestimate_rate = $("div.frm-pika-legend-container .is-selected-rate-orange, div.frm-pika-legend-container .is-selected-day-small-moves-red").map(function () {
				return $(this).text().replace(' Only', '')
			}).get().join(', ')

			$('#ottawamovingestimate-rate').val(ottawamovingestimate_rate)
		}
	}

	// Capture details about the selected date into hidden fields for inclusion in DB & emails on submission
	function captureMoveDateDetails(d) {
		debug_to_console_js("GCAL HIDDENS BEFORE UPDATING: [EFFECTIVE RATE, OVERRIDES ] = [ " + $("#GCAL_EFFECTIVE_RATE").val() + ", " + $("#GCAL_OVERRIDES").val() + "]");
		if (d) {
			var effectiveRate = getEffectiveRate(d).toUpperCase();
			var overridesList = getGCALOverrides(d).toUpperCase();
			$("#GCAL_EFFECTIVE_RATE").val(effectiveRate);
			$("#GCAL_OVERRIDES").val(overridesList);
		}
		debug_to_console_js("GCAL HIDDENS AFTER UPDATING: [EFFECTIVE RATE, OVERRIDES ] = [ " + $("#GCAL_EFFECTIVE_RATE").val() + ", " + $("#GCAL_OVERRIDES").val() + "]");
	}

	// Test for big moves (2+ bedrooms) being booked on small move dates (used to alert user and disable submission)
	function blockBigMovesOnSmallMoveDays(d) {
		// Is Big Move selected?
		var roomsSelected = $("[name='" + ORIG_BEDROOM_FIELD_NAME + "']").val();
		//alert( "ROOMS SELECTED: " + roomsSelected );
		var bBigMoveSelected = ($.isNumeric(roomsSelected) && (roomsSelected >= 2));
		// Is Small Move date selected?
		var bSmallMoveDay = isOverride(d, "SMALLMOVES");
		// If Big Move on Small Move date then prevent submission and notify customer
		if (bSmallMoveDay && bBigMoveSelected) {
			msg("Unfortunately, we only have room for small moves on the date you have chosen.<br /><br />Please choose a different date or contact us to discuss what dates are available for moves of this size.<br /><br />Thank you.", "Small Moves Only (Maximum 1 Bedroom)", true);
			disableSubmit();
		} else {
			enableSubmit();
		}
	}

	//RG-MAY2021-2/2: Handle Bedroom Selection Change
	//NOTE: Fetching date selected here instead of doing an override of blockBigMovesOnSmallMoveDays with move date param
	$("[name='" + ORIG_BEDROOM_FIELD_NAME + "']").change(function () {
		// *** ONLY IF *** a valid move date is selected, then do the blockBigMovesOnSmallMoveDays validation
		// 1] Get the move date selected (same method as used in form validation)
		//var mMoveDateSelected = moment(createDateString(intMoveYear,intMoveMonth,intMoveDay), DATE_FORMAT_YYYYMMDD);
		// 2] Get the move date selected (same method as used in handleMoveDateChange)
		if (g_MoveDatePicker.value && (g_MoveDatePicker.value.toLowerCase() !== INVALID_DATE.toLowerCase())) {
			var mMoveDateSelected = moment(g_MoveDatePicker.value, DATE_FORMAT_OUTPUT);
			//alert("DATE SELECTED: " + mMoveDateSelected);
			blockBigMovesOnSmallMoveDays(mMoveDateSelected);
		}
	});

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// HELPERS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// DIALOG HELPERS (Custom dialogs / JQ UI Dialog)
	// JUST POSITION (reused for init AND recentering on scroll/resize)
	function getDialogPosition() {
		return { my: "center", at: "center", of: window, within: window, collision: "fit" };
	}
	// ALL OPTIONS
	function getDialogOptions() {
		//return dialogOptions = {
		return {
			title: "Attention",
			dialogClass: "no-close",		// see CSS for hiding the close button
			width: "auto", 			// vs 500 (without quotes) * auto benefit: prevents wrapping of line lines that don't have <br>s
			//minWidth:	300,				// ensure small messages still render a decent dialog (minWidth is not respected when on "auto" width as well)
			autoOpen: false,  			// will render when called with show()
			modal: true,
			draggable: false,
			resizable: false,
			// OTHER POSITIONING OPTION (cannot center within the form panel this way)
			//position:	{ my: "center top", at: "center middle", of: "div.col-lg-9", within: window, collision: "fit" },
			// GOOD CENTERED ON WINDOW
			position: getDialogPosition(),
			//TODO-MSG: THIS SHOULD BE CALCULATING CENTER OF THE CONTAINER PANEL AND WIDTH OF DIALOG PARENT LAYER
			//position:	{ my: "left center", at: "((($(window).width() - $FRM_DIALOG.parent().outerWidth()) / 2) - 100) center", of: window, within: window, collision: "fit" },





			show: { effect: "highlight", color: "#ffff99" },		// "highlight",   NOTE: Orange Caution color: #fece2f, Red alert color: #f02020, Yellow validation color: #ffff99
			hide: { effect: "fade" }, 										// "fade",
			buttons: [{ text: "OK", click: function () { $(this).dialog("close"); } }],
			open: function (event, ui) {
				// Keep these above other page elements (especially the pikaday calendar @ z-index 9999)
				$(".ui-widget-overlay").css("zIndex", 10000);
				$(".ui-dialog").css("zIndex", 10001);
				// Prevent scrolling when dialog is shown (causes minor shift due to scrollbar removal)
				$("body").addClass("stop-scrolling");
				// Fake a resize to the DOM so the dialog centers properly (instead of all the other attempts, this works in Chrome and must test other browsers)
				fakeResize();
			},
			close: function (event, ui) {
				// Re-enable scrolling after dialog is shown (causes minor shift due to scrollbar removal)
				$("body").removeClass("stop-scrolling");
			},
		}
	}
	function initJQDialog() {
		$FRM_DIALOG = $("<div id=\"dialog\"></div>").dialog(getDialogOptions());
		// And force recentering if the winow is resized or if the window scrolls (programatically since we disable the scrolling on displaying modals)
		// NOTE: event.type "scroll"/"resize" equivalent to $(window).scroll/resize(...)
		$(window).on("scroll resize", function (event) {
			recenterDialog();
		});
	}
	function recenterDialog() {
		$FRM_DIALOG.parent().position(getDialogPosition());
	}
	function fakeResize() {
		window.dispatchEvent(new Event('resize'));
	}
	// ES6: function msg(s, strTitle = "Attention", bUrgent = false) {
	function msg(s, strTitle, bUrgent) {

		// Always show an alert on mobile devices smaller than the default viewport of 620 width (which all phones are, tablets mostly start at 768 width)
		// NOTE: This is to prevent the issue of dialogs showing offscreen and requiring users to zoom out (because it zooms in when user enters data or submits then focuses on a field)
		// ref: https://mediag.com/blog/popular-screen-resolutions-designing-for-all/ + XLS / MysQL for all tracked browsers and widths
		if (screen.width <= 620) {
			// BACKUP IS FORMATTED ALERT FOR DEVICES SMALLER THAN 620px VIEWPORT (handling line breaks), optionally adding title as a line if one is passed in
			var titleRowIfDefined = strTitle ? strTitle + "\n\n" : "";
			alert("\n" + titleRowIfDefined + s.replace("request,<br />as", "request, as").replace(/<br \/>/gi, "\n").replace(/<br\/>/gi, "\n") + "\n");
		} else {

			// Show a JQ Dialog
			strTitle = strTitle || "Attention";
			bUrgent = bUrgent || false;
			try {
				// Initialize the JQ Dialog the first time it is required
				if ((!$FRM_DIALOG) || (typeof $FRM_DIALOG === "undefined")) initJQDialog();
				// Prepare the dialog
				var strIconColor = bUrgent ? "#f02020" : "#fece2f";
				var strHighlightColor = bUrgent ? "#ffaaaa" : "#ffff99";
				var strHtml = "<br /><p style=\"display: flex;align-items: center;\"><span class=\"ui-icon ui-icon-alert\" style=\"float:left;width:50px !important;margin:0 10px 0 0;font-size:36px;color:" + strIconColor + ";\"></span>" + s + "</p>";
				// Show the dialog
				if (bUrgent) $(".ui-dialog-titlebar").addClass("error-title-bar"); else $(".ui-dialog-titlebar").removeClass("error-title-bar");
				// IMPORTANT: Do not delay this call without properly accomodating reflow due to UI work, focusing, etc (like reflow hack of accessing offsetHeight etc)
				var IMP_DELAY_ZERO = 0;
				$FRM_DIALOG.dialog("option", { title: strTitle, show: { effect: "highlight", color: strHighlightColor, delay: IMP_DELAY_ZERO, duration: 200 } }).html(strHtml).dialog("open");

			} catch (ex) {
				alert("Sorry, we cannot process your request at this time.\n\nPlease wait or reload the page.");
				debug_to_console_js("DIALOG ERROR: " + ex.message + " >>> " + ex.stack);
			}

		}
	}

	// PIKADAY HELPERS
	// dayOffset - USE THIS TO ENSURE THE PIKDAYRESPONSIVE CALENDAR RETURNS PICKED DATES ETC AS THE PROPER DATE FOR THE DEFAULT TIMEZONE (on "change" event for move date)
	function getCalendarDaysToOffsetToDefaultTimezone() {
		// Cannot change the Date created from the selected pika-button's "data" attributes, but can use PR's offset to correct the output of the date selected
		// If the user's timezone is later than the application's timezone then adjust (tell PikadayResonsive to adjust all dates internally)
		// Conversely, if the application is more than 24 hours ahead of the browser then a negative offset is required
		return ((MOMENT_UTC_OFFSET - BROWSER_UTC_OFFSET) / 60 <= -24) ? 2 : 	// APP BEHIND >=24 HRS ==> +2d OFFSET
			(MOMENT_UTC_OFFSET < BROWSER_UTC_OFFSET) ? 1 : 					// APP BEHIND 0-23 HRS ==> +1d OFFSET
				((MOMENT_UTC_OFFSET - BROWSER_UTC_OFFSET) / 60 >= 24) ? -1 : 	// APP AHEAD  <=24 HRS ==> -1d OFFSET
					0;																				// APP AHEAD  0-23 HRS ==>  NO OFFSET
	}
	// minDate - USE THIS TO ENSURE THE PIKDAYRESPONSIVE CALENDAR SETS THE MINDATE CORRECTLY (by default, the JS conversion drops the hours so adjustment is required)
	function getCalendarMinDateForDefaultTimezone() {
		// The mindate needs to be corrected in either direction if done using MOM_TODAY_EST, but better to just create a clean LOCAL-TIMEZONE JS date based on MOM_TODAY_EST's YYYY,MM,DD
		// NOTE: The timezone of the JS Date should not matter in this approach, it will be the correct DATE and Pikaday does not convert to moment / any timezone, then drops the hours
		return new Date(MOM_TODAY_EST.format("YYYY"), MOM_TODAY_EST.format("MM") - 1, MOM_TODAY_EST.format("DD")); // Month is zero-indexed
		//TESTING - CAN USE THIS:
		//		return new Date(2019,0,1);
		//BEFORE TIMEZONE FIX:
		//		return MOM_TODAY_EST.clone().add(0,"days");	// Other more complex solution for correct EST mindate would be to determine a dayOffset and .add(X) instead of 0
	}

	// MOMENT HELPERS
	// Get today's date in EST at zero-hour (drop the time from the local timezone) * noting this creates the new moments in the defaul timezone (NY/EST)
	// NOTE: this is semantically equivalent to moment(moment().format(DATE_FORMAT_YYYYMMDD))
	function getTodaysDateEST() {
		return moment().startOf("day");
	}
	// FOR PIKADAY.JS *** ONLY ***: Convert a moment to EST-based zero-hour moment, adjusted for timezone offset when redrawing the calendar and selecting the right "is-selected" date
	function getESTAdjustedZeroHourMoment_PikadayJS(jsDate) {
		//TODO:NOTE: When testing earlier timezones again, if there is a date-picking highlighting issue, then maybe need to bring back in the CAL_DAYS_TO_OFFSET logic
		//OLD ONE-LINER: return moment(jsDate).add(CAL_DAYS_TO_OFFSET, "days").startOf("day");

		// Now also calling the same way disableDayFn does
		return getESTAdjustedMomentFromBrowserTZ(jsDate);
	}
	// FOR DISABLEDAYFN DIRECTLY (and TBD for onSelect adjustment of dates that push ahead or back due to JS DATE in USER local timezone vs the date passed in)
	function getESTAdjustedMomentFromBrowserTZ(jsDate) {
		// Convert the localized zero-hour JS Date for each pikaday to an EST-based zero-hour moment using JUST THE YYYY-MM-DD portion as it is drawing THAT day
		// NOTE: No longer applying the computed dayOffset like PR internals because this moment is created with a JS Date object by Pikaday
		var pikaDateInBrowserTZ = moment(jsDate).tz(BROWSER_TZ);	// No need for startOf("day") or anything, it is zero-based in the original browser timezone
		var pikaDate_EST = moment(pikaDateInBrowserTZ.format("YYYY") + "-" + pikaDateInBrowserTZ.format("MM") + "-" + pikaDateInBrowserTZ.format("DD"), DATE_FORMAT_YYYYMMDD);
		return pikaDate_EST;
	}


	// GENERIC HELPERS
	function createDateString(yyyy, mm, dd) {
		// NOTE: Creates string in "YYYY-MM-DD" format (DATE_FORMAT_YYYYMMDD)
		//ES6: return `${yyyy}-${mm}-${dd}`;
		return (yyyy + "-" + mm + "-" + dd);
	}
	function getUrlParameter(name) {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		var results = regex.exec(location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}
	function debug_to_console_js(text) {
		if (g_DEBUGGING) console.log("[DEBUG] " + text);
	}
	function warn_to_console_js(text) {
		// NOTE: Keep resist-fingerprinting disabled or precision may reduce to 100ms
		if (g_DEBUGGING) console.warn("[WARNING] " + text);
	}
	function error_to_console_js(text) {
		// NOTE: Keep resist-fingerprinting disabled or precision may reduce to 100ms
		console.error("[ERROR] " + text);
	}
	// This exists() version (implying chaining is possible) works in IE, Chrome, FF, Safari
	$.fn.exists = function () { return ($(this).length > 0); };


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Old protection logic (Matt's?) - only works in really old browsers and is non-standard unless protecting images from novices which is easy to do with other script
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	var message = "Copyright 2010 by First Rate Movers. All rights reserved. WARNING ! All content contained within this site is protected by copyright laws. Unauthorized use of our material is strictly prohibited.";
	function click(e) {
		if (document.all) {
			if (event.button == 2 || event.button == 3) {
				alert(message);
				return false;
			}
		}
		if (document.layers) {
			if (e.which == 3) {
				alert(message);
				return false;
			}
		}
	}
	if (document.layers) {
		document.captureEvents(Event.MOUSEDOWN);
	}
	document.onmousedown = click;

	// Estimates App export var, exposing public props/methods
	// NOTE: exports are only needed for pikaday-min.js customization, rest are so date debugger works
	return {
		// Public Variables (constants or set by export time)
		g_DEBUGGING_DATES: g_DEBUGGING_DATES,			// ALTERNATIVE IF DECLARING return AS VAR AT TOP: function() { return g_DEBUGGING_DATES },
		g_DO_NOT_DISABLE_DAYS: g_DO_NOT_DISABLE_DAYS,
		DATE_FORMAT_OUTPUT: DATE_FORMAT_OUTPUT,
		DATE_FORMAT_DEBUG: DATE_FORMAT_DEBUG,
		MOVE_DATE_INPUT_FIELD_NAME: MOVE_DATE_INPUT_FIELD_NAME,
		INVALID_DATE: INVALID_DATE,
		DEFAULT_TIMEZONE: DEFAULT_TIMEZONE,

		// Public Objects (or vars that change) * workaround, have to call as function()
		g_MoveDatePicker: function () { return g_MoveDatePicker },
		$g_movedate: function () { return $g_movedate },
		$g_movedateInput: function () { return $g_movedateInput },
		MOM_TODAY_EST: function () { return MOM_TODAY_EST },
		MOMENT_TZ_EST: function () { return MOMENT_TZ_EST },
		MOMENT_UTC_OFFSET: function () { return MOMENT_UTC_OFFSET },
		BROWSER_UTC_OFFSET: function () { return BROWSER_UTC_OFFSET },
		BROWSER_TZ: function () { return BROWSER_TZ },
		CAL_DAYS_TO_OFFSET: function () { return CAL_DAYS_TO_OFFSET },

		// ALTERNATIVE IF DECLARING return AS VAR AT TOP: ,
		// g_DEBUGGING_DATES: 	function() { return g_DEBUGGING_DATES },
		// g_MoveDatePicker: 	function() { return g_MoveDatePicker },
		// DATE_FORMAT_OUTPUT:	function() { return DATE_FORMAT_OUTPUT },

		// Public Methods
		IsValidDate: IsValidDate,
		createDateString: createDateString,
		msg: msg,
		getCalendarMinDateForDefaultTimezone: getCalendarMinDateForDefaultTimezone,
		getTodaysDateEST: getTodaysDateEST,
		// For form php
		submitForm: submitForm,
		// For Pikaday-min.js customization
		getESTAdjustedZeroHourMoment_PikadayJS: getESTAdjustedZeroHourMoment_PikadayJS,
	}

})(window.jQuery, window, document);
